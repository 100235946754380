import { AnimatronProduct } from 'types/global';
import { User } from 'user';

import getStudioSubscription from '../subscription/getStudioSubscription';
import getWaveSubscription from '../subscription/getWaveSubscription';
import waitFor from '../waitFor';

declare global {
  interface Window {
    FrontChat: any;
  }
}

export const load = () => {
  function append() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.defer = true;
    s.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';

    setTimeout(() => document.body.appendChild(s), 1500);
  }
  if (document.readyState === 'complete') {
    append();
    return;
  }
  window.addEventListener('load', append, false);
};

type InitOptions = {
  chatId: string;
  product: AnimatronProduct;
  welcomeMessageAppearance?: string;
  useDefaultLauncher?: boolean;
};
let initCompleted: Promise<InitOptions>;
export const init = (options: InitOptions) => {
  initCompleted = new Promise(resolve =>
    resolve({ welcomeMessageAppearance: 'hidden', useDefaultLauncher: true, ...options })
  );
};

export const frontChat = async (...a: any[]) => {
  await waitFor(() => !!window.FrontChat, 50, 'wait for FrontChat loaded');
  window.FrontChat && window.FrontChat(...a);
};

const initFrontChat = async (newOptions: { [key: string]: any }) => {
  const options = await initCompleted;

  frontChat('shutdown', { clearSession: true });
  setTimeout(
    () =>
      frontChat('init', {
        ...options,
        ...newOptions
      }),
    0
  );
};

export const setUser = async ({
  username,
  userIdAsString,
  displayName,
  email,
  meta: { frontStudioHash, frontWaveHash },
  subscriptionDetails
}: User) => {
  if (!userIdAsString) return;

  const { product = 'WAVE' } = await initCompleted;
  initFrontChat({
    name: displayName,
    userHash: product === 'WAVE' ? frontWaveHash : frontStudioHash,
    userId: userIdAsString,
    contact: {
      email,
      customFields: {
        Username: username,
        Admin: `https://webadmin.animatron${
          process.env.TS_ENV === 'prod' ? '' : '-test'
        }.com/admin/user.html?userId=${userIdAsString}`,
        'Studio Plan': getStudioSubscription(subscriptionDetails)?.name,
        'Wave Plan': getWaveSubscription(subscriptionDetails)?.name
      }
    }
  });
};

export const setAnonymous = () => {
  initFrontChat({
    name: 'Guest'
  });
};

export const openChat = async () => {
  await initCompleted;

  frontChat('show');
};
