import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import * as frontChat from '@anm/helpers/frontChat';
import isInIframe from '@anm/helpers/is/isInIframe';
import * as semrush from '@anm/helpers/semrush';
import { useEffect } from 'react';
import { AnimatronProduct } from 'types/global';
import { User } from 'user';

const getCanLoadIntercom = () => !isInIframe() && !semrush.isSemrushIframe();

let initedBy: 'anonymous' | string = 'anonymous';
const updateChat = (profile: User | null) => {
  const { setUser, setAnonymous } = frontChat;

  if (profile) {
    if (initedBy !== profile.userId) {
      setUser(profile);
      initedBy = profile.userId;
    }
  } else if (!hasAuthAccess()) {
    setAnonymous();
    initedBy = 'anonymous';
  }
};

type UseIntercomProps = {
  id: string;
  user: User | null;
  enable: boolean;
  hide_default_launcher?: boolean;
  product: AnimatronProduct;
};

const useIntercom = ({ id, user, enable, hide_default_launcher, product }: UseIntercomProps) => {
  const canLoadIntercom = enable && getCanLoadIntercom();

  useEffect(() => {
    if (!canLoadIntercom) return;

    frontChat.load();
    frontChat.init({ product, chatId: id, useDefaultLauncher: !hide_default_launcher });
  }, [canLoadIntercom]);

  useEffect(() => {
    if (!canLoadIntercom) return;

    updateChat(user);
  }, [canLoadIntercom, user?.userId]);
};

export default useIntercom;
